@import "_palette.scss";

#region-lightbox {
    display: flex;
    position: fixed;
    z-index: 200;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: none;
    pointer-events: all;
    background-color: rgba(0,0,0,.9);
    transition: opacity 125ms ease-in-out,background-color 125ms ease-in-out,backdrop-filter 125ms ease-in-out,-webkit-backdrop-filter 125ms ease-in-out,transform 125ms ease-in-out;

    .region-lightbox-inner{
        padding: 0 0 2rem 2rem;
        width: 40em;
        height: 18em;
        background-color: $adobe-white;

        >h2 {
            font-family: 'AdobeCleanBold';
            margin-bottom: .5rem;
            margin-top: 3rem;
            font-weight: 900;
            font-size: 28px;
            line-height: 1.5;
            color: #000;
        }

        >p {
            font-weight: 500;
            font-size: 16px;
            line-height: 1.5;
            margin: 0;
        }

        .region-lightbox-cols{
            display: grid;
            grid-template-columns: 33% 33% 33%;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                p {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 1.2;
                    margin-bottom: 0;
                }

                li {
                    font-family: 'AdobeCleanSemiLight';
                    line-height: 1.2;
                    transition: color 0.2s;
                    cursor: pointer;

                    a{
                        font-size: 14px;
                        color: $adobe-lightblue;
                        &:hover {
                            text-decoration: underline;
                            outline-width: 0;
                        }
                        &:focus {
                            text-decoration: underline;
                            text-decoration-style: double;
                        }
                    }

                    @media screen and (max-width: 994px) {
                        margin: 0px 12px;
                    }
            
                    @media screen and (max-width: 415px) {
                        margin: 0px 6px;
                    }
                }
            }
        }

        button{
            position: relative;
            float: right;
            margin: .5rem;
            background-color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
            height: 20px;
            width: 20px;
            border: none;
            border-radius: 50%;
            transition: all 0.5s;
    
            &:hover { background-color: rgba(0, 0, 0, 0.5); }
            &::before { height: 2px; width: 50%; }
            &::after { height: 50%; width: 2px; }
            &::before, &::after {
                background-color: white;
                content: "";
                display: block;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translateX(-50%) translateY(-50%) rotate(45deg);
                transform-origin: center center;
            }
        }
    }   
}

.lightbox-hide{
    visibility: hidden;
}