@import './mixins';
@import './palette';

.hero-banner-wrapper{
    height: 95vh;
    .hero-banner-background{
        width: 100%;
        height: 100%;
    }
    .hero-banner-heading{
        position: absolute;
        top: 100px;
        width: 100%;
        text-align: center;
        font-size: 44px;
        line-height: 55px;
        font-weight: 700;
        color: #ffffff;

        @include bpMax(413px) {
            font-size: 36px;
            line-height: 44px;
        }
    }
    .hero-banner-logo{
        position: absolute;
        top: 300px;
        left: 50%;
        transform: translate(-50%,0);
        @include bpMax(413px) {
            top: 250px;
            width: 160px;
        }
    }
    .hero-banner-text{
        position: absolute;
        bottom: 110px;
        margin: 0;
        font-size: 32px;
        line-height: 40px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        width: 100%;
        @include bpMax(413px) { 
            font-size: 28px;
            line-height: 32px;
        }
    }
    .down-arrow-left, .down-arrow-right {
        position: absolute;
        width: 24px;
        height: 0px;
        bottom: 80px;
        border: 1px solid #FFFFFF;
        transform: rotate(45deg);
    }
    .down-arrow-left{
        right: calc(50% - 8px);
        transform: rotate(45deg);
    }
    .down-arrow-right{
        right: calc(50% - 24px);
        transform: rotate(135deg);
    }
}

.mobile-section {
    position: relative;
    overflow: hidden;

    .anchor-point{
        height: 0;
        position: relative;
        bottom: 50px;
    }

    &.text-white { color: $adobe-white; }

    &::before {
        position: absolute;
        top: -160px;
        right: -90px;
        content: url('../assets/images/mobile-bg-corner.svg');

    }

    &.bg-red { background-color: $adobe-red; }
    &.bg-black { background-color: $adobe-black; }
    &.bg-offwhite { background-color: $adobe-offwhite; }
}

.body-section-wrapper {
    padding: 100px 0 30px 0;

    h3, p {
        padding: 0 20px;

        &.red { color: $adobe-red; }
    }

    img { 
        display: block;
        width: 100%; 
        margin: 30px auto 60px auto;

        &.small { width: 40%; }
        &.medium { width: 70%; }
        &.left { transform: translateX(-5%); }
        &.right { transform: translateX(20%); }
    }

    button {
        margin: 0 20px;
    }

    &.no-bottom-padding {
        padding-bottom: 0px;

        img:last-of-type { margin-bottom: 0; }
    }

    .right-padding { padding-right: 16px;}
    .left-padding { padding-left: 16px;}
    .disclaimer { font-size: 16px;}
}