@import './palette.scss';

.section-heading {
    position: relative;
    padding: 0 20px 20px 20px;
    // padding-bottom: 20px;

    &::after {
        content: '';
        height: 5px;
        width: 40%;
        position: absolute;
        bottom: 0;
        left: 20px;
    }
    &.border-white::after { background-color: $adobe-white; }
    &.border-red::after { background-color: $adobe-red; }

}