

.toast-wrapper {
    position: fixed;
    display: none;
    justify-content: space-between;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    top: 7.5rem;
    z-index: 50000;
    width: 80%;
    max-width: 400px;
    border-radius: 5px;
    padding: 10px;
    background-color: #b3da8a;

    &.open {
        display: flex;
        animation: fadeInOut;
        animation-duration: 5s;
        animation-iteration-count: 1;
    }

    .content-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .content {
            margin: 0;
            margin-left: 15px;
        }
    }

    .close-icon {
        cursor: pointer;
    }
}

@keyframes fadeInOut {
    0% { opacity: 0; }
    20% { opacity: 1; }
    80% { opacity: 1; }
    100% { opacity: 0; }
}