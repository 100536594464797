@import './styles/palette';
@import './styles/fonts';
@import './styles/mixins';

* {
  box-sizing: border-box; 
}

body {
  overflow: hidden;

  @media screen and (max-width: 994px) {
        overflow-x: hidden;
        overflow-y: scroll;
    }
}

#onetrust-pc-sdk, #ot-cookie-settings {
    font-family: 'AdobeCleanRegular';

    h2, h3, h4, h5, h6 {
        font-family: 'AdobeCleanBold';
    }
}

.App {
  height: 100vh;
  width: 100vw;
  
  #animation_container {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &.hide { display: none; }
    &.loaded {
        background: #050303;
    }
   
    #canvas {
      display: block;
      height: 100%;

      @media screen and (max-height: 720px) {
        max-width: 205vh;
        min-width: 950px;
      }
    }

    #dom_overlay_container {
      pointer-events: none;
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  #_preload_div_ {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 48;
  }
}