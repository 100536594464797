@import "_palette.scss";

#footer {
    position: absolute;
    bottom: 0;
    position: fixed;
    background-color: $adobe-white;
    width: 100%;
    padding: 0 30px;
    z-index: 100;
    height: 45px;
    transition: background-color 0.3s, opacity 0.3s;

    &.hidden {
        opacity: 0;
    }

    &.scrolled {
        background-color: rgba(255, 255, 255, 0.75);
    }

    &:hover {
        background-color: $adobe-white;
    }

    @media screen and (max-width: 994px) {
        padding: 0px 15px;
    }
 
    .footer-inner {
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: center;
        width: 100%;
        max-width: 1440px;
        margin: auto;

        @media screen and (max-width: 994px) {
            justify-content: space-around;
        }

        @media screen and (max-width: 500px) {
            text-align: center;
        }

        ul {
            display: flex;
            align-items: center;
            list-style: none;
            padding: 0px;

            li {
                font-family: 'AdobeCleanSemiLight';
                font-size: 11px;
                margin: 0px 20px;
                color: $adobe-darkergrey;
                transition: color 0.2s;
                cursor: pointer;

                @media screen and (max-width: 994px) {
                    margin: 0px 12px;
                }
        
                @media screen and (max-width: 415px) {
                    margin: 0px 6px;
                }

                &:hover {
                    color: $adobe-lightgrey;
                }

                &.region{
                    display: flex;
                    align-items: center;
                    img{
                        width: 20px;
                        margin-right: 10px;
                    }
                    &::after{
                        display: block;
                        margin-left: 7px;
                        color: inherit;
                        transform-origin: center center;
                        transform: scaleX(2);
                        content: "\2228";

                        @media screen and (max-width: 500px) {
                            display: none;
                        }
                    }
                }

                &.end {
                    margin-right: 40px;
                    @media screen and (max-width: 994px) {
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}