@import './_palette';
@import './mixins';

.faas-container {
    width: 100%;
    height: 100vh;
    background: $adobe-white;
    position: fixed;
    z-index: 50;
    opacity: 1;
    animation: fadeIn ease 0.4s;
    overflow-y: scroll;

    @media screen and (max-width: 994px) {
        position: static;
        overflow-y: unset;
    }

    &.hidden {
        display: none;
    }

    .faas-screen-text {
        text-align: right;
        padding-left: 20%;

        h2 {
            font-family: 'AdobeCleanBold';
            font-size: 50px;
        }
    }

    .faas-inner {
        max-width: 1400px;
        height: 100%;
        min-height: 90%;
        margin: auto;
        display: grid;
        grid-template-columns: 3fr 4fr;
        align-items: center;
        overflow-y: hidden;
        padding: 20px 0px;

        .faas-grey{
            background-color: $adobe-darkgrey;
            margin-right: 15px;
            &:hover{
                background-color: $adobe-lightgrey;
            }

            @media screen and (max-width: 768px) {
                display: none;
            }
        }

        h1 {
            margin-top: 0px;
        }

        .sup {
            font-size: 14px;
        }

        @media screen and (max-width: 1441px) {
            margin: 0 2em;

            .faas-content {
                transform: scale(0.9);
            }
        }

        @media screen and (max-width: 994px) {
            margin: 0 1em;
            grid-template-columns: 1fr;
            overflow-y: hidden;
        }
    }

    .learn-more-container {
        width: 100%;
        background: linear-gradient(to bottom, #FA0F00, #B00D08);
        display: flex;
        justify-content: center;

        @media screen and (max-width: 994px) {
            height: 100vh;
        }

        .learn-more-inner {
            width: 35%;
            max-width: 450px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 150px 0;

            @media screen and (max-width: 994px) {
                width: 80%;
                text-align: center;
                margin: 0;
            }

            img { width: 50%; max-width: 100px; }

            h2, p, .btn {
                color: $adobe-white;
                text-align: center;
            }

            h2 {
                font-family: 'AdobeCleanBold';
                font-size: 50px;
                margin-bottom: .25em;
            }

            .btn {
                background: transparent;
                border: 2px solid $adobe-white;
                padding: 5px 32px;
            }
        }
    }

    &.final {
        height: inherit;
        min-height: 100vh;

        @media screen and (max-width: 994px) { min-height: unset; }

        .faas-inner {
            height: auto;

            .faas-content {
                p { padding: 0; }
            }
            .faas-form {
                max-height: fit-content;
                overflow: visible;
                margin-top: 2em;

                @media screen and (max-width: 994px) { margin-top: 0px; }
            }
        }
    }
}

.faas-form {
    display: grid;
    position: relative;
    font-family: 'AdobeCleanBold';
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px 20px;
    min-width: 725px;
    color:  $adobe-black;
    background: $adobe-white;
    border-radius: 8px;
    padding: 2.2em;
    max-height: calc(100vh - 120px);
    overflow: scroll;

    @include bpMax(1441px) { 
        padding: 2em ;
        top: 10px;
    }

    .note {
        grid-column: span 2;
        text-align: center;
        font-size: 13px;
    }

    .row {
        display: flex;
        flex-direction: column;

        &.checkbox {
            flex-direction: row;
        }
    }

    label {
        margin-bottom: 10px;
        font-size: 14px;
        // font-weight: bold;
    }

    input:not([type='checkbox']), select, textarea {
        padding: .65rem 1rem;
        width: 100%;
        box-sizing: border-box;
        border: 0 none;
        box-shadow: inset 0.067rem 0.067rem 0 0 #d5d5d5, inset -0.06231rem -0.06231rem 0 0 #d5d5d5;
        color: #535353;
        -webkit-appearance: none;
        border-radius: 0px;
    }

    textarea {
        resize: none;
    }

    #showhidetoggle {
        display: none;
    }

    select {
        background: #FAFAFA;
    }

    .democheckbox {
        box-shadow: none;
        width: auto;
        margin-bottom: 10px;
        margin-left: 0px;
    }

    .legal {
        grid-column: span 2;
        border-top: 1px solid #d5d5d5;
        font-size: 13px;

        a {
            font-weight: bold;
            text-decoration: none;
        }

        .legalnotice {
            font-size: 12px;
            margin-top: 1em;
        }
    }

    .row.submit {
        grid-column: span 2;
        align-self: center;
    }

    input[type=submit] {
        font-family: 'AdobeCleanBold';
        box-sizing: border-box;
        min-height: 32px;
        height: auto;
        line-height: 1.3;
        min-width: 72px;
    
        color: $adobe-white;
        background-color: $adobe-lightblue;
    
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        text-transform: none;
    
        cursor: pointer;
        
        border-width: 2px;
        border-style: solid;
        -webkit-font-smoothing: antialiased;
        border-radius: 16px;
    
        padding: 4px 14px;
        padding-bottom: 4.5px;
        padding-top: 3.5px;
    
        border-radius: 20px;
        border: none;

        box-shadow: none;
        width: 15%;
        margin: auto;
        
        transition: background .13s ease-out,border-color .13s ease-out,color .13s ease-out,box-shadow .13s ease-out;
    
        &:hover {
            background-color: $adobe-darkblue;
        }
    }

    #showhidetoggle {
        grid-column: span 2;

         a {
            align-self: center;
         }
    }

    .error {
        input, select {
            border: 1.5px solid red;
        }

        .errorMessage {
            color: red;
            font-size: 14px;
            margin-top: 10px;
        }
    }

    .errorSummary {
        grid-column: span 2;
        color: red;
        display: none !important;
    }
}

@media screen and (max-width: 767px) {
    .faas-form {
        margin: 0px auto;
        max-width: 100%;
        width: 100%;
        min-width: unset;
        padding: 1.5em 0px;
        grid-gap: unset;

        .row {
            grid-column: span 2;
            margin-bottom: 20px;
        }

        input[type=submit] {
            width: 32%;
        }
    }
};

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}