@import './_palette';
@import './mixins';

.learn-more-container {
    width: 100%;
    background: linear-gradient(to bottom, #FA0F00, #B00D08);
    display: flex;
    justify-content: center;

    @media screen and (max-width: 994px) {
        height: 100vh;
    }

    .learn-more-inner {
        width: 35%;
        max-width: 450px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 150px 0;

        @media screen and (max-width: 994px) {
            width: 80%;
            text-align: center;
            margin: 0;
        }

        img { width: 50%; max-width: 100px; }

        h2, p, .btn {
            color: $adobe-white;
            text-align: center;
        }

        h2 {
            font-family: 'AdobeCleanBold';
            font-size: 50px;
            margin-bottom: .25em;
        }

        .btn {
            background: transparent;
            border: 2px solid $adobe-white;
            padding: 5px 32px;
        }
    }
}