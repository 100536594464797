@mixin flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin bpMax($pixelWidth) {
    @media screen and (max-width: $pixelWidth) {
        @content;
    }
}