#debug-log {
    height: 100vh;
    width: 50vw;
    display: none;
    overflow-y: scroll;
    position: absolute;
    z-index: 200;
    padding: 30px;
    background-color: white;
    &.show { display: block; }
}