@import "_mixins.scss";
@import "_palette.scss";

#navigation {
    position: fixed;
    background-color: $adobe-white;
    top: 0;
    width: 100%;
    padding: 0 30px;
    z-index: 100;
    height: 60px;

    transition: background-color 0.3s;
    &.scrolled {
        background-color: rgba(255, 255, 255, 0.95);
    }

    &:hover {
        background-color: $adobe-white;
    }

    @include bpMax(991px) { height: 60px; padding-top: 10px; }
    @include bpMax(768px) { padding: 0px 20px 0px 20px; }
    @include bpMax(413px) { padding: 0px 10px 0px 10px; }

    .mobile-shade {
        position: absolute;
        top: 0;
        left: 0; 
        height: 100vh;
        width: 100vw;
        background-color: $adobe-white;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;
    }

    .nav-inner-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: auto;

        height: 100%;
        width: 100%;
        max-width: 1400px;
        
        .left-wrapper {
            @include flexCenter;
            height: 100%;

            @include bpMax(991px) { flex-direction: row; }
            $title-font-size: 14px;
            $title-font-size-mobile: 14px;
            .logo-title-wrapper {
                @include flexCenter;

                .hamburger-menu-icon {
                    min-width: 30px;
                    height: 25px;
                    margin-right: 10px;
                    display: none;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    div { height: 3px; width: 70%; background-color: $adobe-black; }
                    @include bpMax(768px) { display: flex; }
                    @include bpMax(413px) {  
                        height: 20px;
                    }
                }

             
                .nav-image-desktop {
                    width: auto;
                    max-height: 22px;
                    margin-right: 20px;
                    @include bpMax(991px) { margin-right: 10px; }
                    @include bpMax(768px) { display: none; }
                }

                .nav-image-mobile {
                    display: none;
                    height: 27px;
                    width: 30px;
                    @include bpMax(768px) { display: block; }
                    @include bpMax(413px) {  
                        height: 20px;
                        width: 23px;
                    }
                }
                
                .title {
                    height: 100%;
                    margin: 0 10px;
                    padding: 0 35px;
                    font-size: $title-font-size;
                    text-transform: uppercase;
                    // font-weight: 900;
                    @include flexCenter;
                    
                    border-right: 2px solid $adobe-offwhite;
                    border-left: 2px solid $adobe-offwhite;
                    white-space: nowrap;

                    @include bpMax(991px) {
                        padding: 0 10px;
                    }
                    @include bpMax(768px) { 
                        font-size: $title-font-size-mobile; 
                        padding: 0 10px;
                        .adobe-text { display: none; }
                    }
                    @include bpMax(413px) { font-size: 12px;  }
                }
            }
            
            .navigation-buttons {
                padding: 0;
                height: 100%;
                display: flex; 

                list-style-type: none;

                @include bpMax(991px) { margin-bottom: 0; margin-top: 0; }
                @include bpMax(768px) { 
                    position: absolute;
                    top: 0px;
                    left: 0;
                    background-color: $adobe-white;
                    height: 100vh;
                    width: 80vw;
                    min-width: 300px;
                    display: block; 
                    transform: translateX(-100%);
                    transition: transform 0.3s;
                }

                .top-container {
                    height: 70px;
                    width: 100%;
                    @include flexCenter;
                    display: none;
                    justify-content: space-around;
                    
                    @include bpMax(768px) {display: flex; }

                    .menu-icon {
                        height: 40px;
                        width: 32px;
                    }

                    .title {
                        text-transform: uppercase;
                        font-size: $title-font-size-mobile;
                    }
                    .close-btn {
                        height: 40px;
                        width: 40px;
                        position: relative;
                        transform: rotateZ(45deg);

                        &::before {
                            height: 80%;
                            width: 3px;
                        }
                        &::after {
                            height: 3px;
                            width: 80%;
                        }
                        &::before, &::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            background-color: $adobe-darkgrey;
                            transform: translate(-50%, -50%);
                        }
                    }
                }


                li {
                    position: relative;
                    height: 100%;
                    min-width: 80px;
                    color: $adobe-black;
                    @include flexCenter;
                    @include bpMax(991px) { min-width: 70px; }
                    &::after {
                        content: '';
                        height: 2px;
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                        background-color: $adobe-black;
                        @include bpMax(768px) {
                            background-color: $adobe-lightgrey;
                        }
                        opacity: 0;
                        transition: opacity 0.3s;
                    }

                    padding: 0 10px;
                    cursor: pointer;
                    &.active, &:hover {
                        &::after { opacity: 1; }
                    }

                    $margin-amt: 20px;
                    @media screen and (min-width: 992px) and (max-width: 1199px) {
                        $margin-amt: 15px;
                    }
                    @include bpMax(991px) {
                        $margin-amt: 0px;
                    }
                    &:first-of-type { margin-right: $margin-amt; }
                    &:last-of-type { margin-left: $margin-amt; }

                    @include bpMax(768px) { 
                        height: 50px;
                        margin: 0 10px;
                    }

                }
            }
        }

        .right-wrapper {

            .btn {
                // @include bpMax(768px) { font-size: 15px; }
                // @include bpMax(413px) { 
                //     font-size: 12px;  
                //     // padding: 0px 6px;
                // }
            }
        }
    }


    &.open {
        .mobile-shade {
            opacity: 0.7;
            pointer-events: all;
        }

        .left-wrapper .navigation-buttons {
            transform: translateX(0);

        }
    }
}