@import './_palette';

.final-screen-container {
    width: 100%;
    height: 100vh;
    background: $adobe-white;
    position: fixed;
    z-index: 50;
    opacity: 1;
    overflow-y: scroll;
    animation: fadeIn ease 0.4s;
    display: flex;
    justify-content: center;
    text-align: center;
    
    .final-screen-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: center;

        h2 {
            font-family: 'AdobeCleanBold';
            font-size: 50px;
            margin-bottom: .25em;
        }

         a > p {
             font-size: 1.2rem;
            color: $adobe-lightblue;
        }
    }

    .faas-grey{
        background-color: $adobe-darkgrey;
        margin-right: 15px;
        &:hover{
            background-color: $adobe-lightgrey;
        }

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    &.hidden {
        display: none;
    }
}