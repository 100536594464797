
$adobe-red: #EB1000;

$adobe-white: #ffffff;
$adobe-offwhite: #F5F5F5;
$adobe-lightgrey: #999999;
$adobe-darkgrey: #6E6E6E;
$adobe-darkergrey: #4B4B4B;
$adobe-black: #2C2C2C;

$adobe-lightblue: #1473E6;
$adobe-darkblue: #0262C1;
