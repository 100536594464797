@import './mixins';

h1, h2, h3, h4, h5, h6, p, i, li, strong, span {
    color: inherit;
}

h1 {
    font-family: 'AdobeCleanBold';
    font-size: 50px;
}

h2 {
    font-family: 'AdobeCleanSemiLight';
    font-size: 34px;
}

h3, h4 {
    font-family: 'AdobeCleanBold';
    font-size: 26px;
}

p {
    font-family: 'AdobeCleanSemiLight';
    font-size: 22px;
}

.title {
    font-family: 'AdobeCleanExtraBold';
    font-size: 13px;
}

p i {
    font-family: 'AdobeCleanRegular';
}

a {
    // font-family: 'AdobeCleanBold';
    // color: $adobe-lightblue;
    font-size: 12px;
    text-decoration: none;
}


p strong {
    font-family: 'AdobeCleanBold';
    font-weight: bold;
}

nav {
    font-size: 14px;
    font-family: 'AdobeCleanRegular';
    .active {
        font-family: 'AdobeCleanBold';
        // font-weight: bold;
    }

    .btn-secondary {
        color: $adobe-lightblue;
    }
}

.btn {
    font-family: 'AdobeCleanBold';
    box-sizing: border-box;
    min-height: 32px;
    height: auto;
    line-height: 1.3;
    min-width: 72px;

    color: $adobe-white;
    background-color: $adobe-lightblue;

    font-size: 14px;
    // font-weight: 600;
    font-style: normal;
    text-transform: none;

    cursor: pointer;
    
    border-width: 2px;
    border-style: solid;
    -webkit-font-smoothing: antialiased;
    border-radius: 16px;

    padding: 4px 14px;
    padding-bottom: 4.5px;
    padding-top: 3.5px;

    border-radius: 20px;
    border: none;
    
    transition: background .13s ease-out,border-color .13s ease-out,color .13s ease-out,box-shadow .13s ease-out;

    &:hover {
        background-color: $adobe-darkblue;
    }

    &-large {
        font-size: 20px;
        padding: 8px 22px;
        margin-top: 12px;
    }

    &.secondary {
        color: $adobe-darkergrey;
        background-color: transparent;
        border: 2px solid $adobe-darkergrey;
    }

    @include bpMax(768px) { font-size: 15px; }
    @include bpMax(400px) { font-size: 12px; }
}

